import { ReactElement, useState } from "react"

interface MultiPage {
  currentPage: ReactElement
  currentPageIndex: number
  nextPage: () => void
  previousPage: () => void
  pages: ReactElement[]
}

export const useMultiPage = (pages: ReactElement[]): MultiPage => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0)

  const currentPage = pages[currentPageIndex]

  const nextPage = () => {
    if (currentPageIndex === pages.length - 1) {
      return
    }
    setCurrentPageIndex(currentPageIndex + 1)
  }

  const previousPage = () => {
    if (currentPageIndex === 0) {
      return
    }
    setCurrentPageIndex(currentPageIndex - 1)
  }

  return {
    currentPage,
    currentPageIndex,
    nextPage,
    previousPage,
    pages
  }
}
