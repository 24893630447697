import { createContext, useState, PropsWithChildren, useEffect } from "react"
import { AllSurveys } from "~/const/globalStrings"

interface SurveyStatusContext {
  status: Record<string, boolean>
  completeSurvey: (surveyName: string) => void
  currentSurvey: string
  currentSurveyNumber: number
  currentStepNumber: number
  setCurrentStepNumber: (currentStepNumber: number) => void
  setTotalSteps: (totalSteps: number) => void
  totalSteps: number
}

export const SurveyContext = createContext<SurveyStatusContext | null>(null)

export type SurveyContextProviderProps = {
  surveyIterationId: string
  surveys: string[]
}

export const SurveyContextProvider = (
  props: PropsWithChildren<SurveyContextProviderProps>
): JSX.Element => {
  const initialState: Record<string, boolean> = {}
  AllSurveys[props.surveyIterationId].forEach(
    (surveyId) => (initialState[surveyId] = props.surveys.indexOf(surveyId) < 0)
  )

  const [status, setStatus] = useState<Record<string, boolean>>(initialState)
  const [currentSurvey, setCurrentSurvey] = useState<string>(
    props.surveys[0] || ""
  )
  const [currentSurveyNumber, setCurrentSurveyNumber] = useState<number>(
    props.surveys.length > 0
      ? AllSurveys[props.surveyIterationId].indexOf(props.surveys[0]) + 1
      : 0
  )
  const [currentStepNumber, setCurrentStepNumber] = useState(0)
  const [totalSteps, setTotalSteps] = useState(0)

  useEffect(() => {
    if (currentSurvey) {
      setTotalSteps(currentSurvey.length)
    }
  }, [currentSurvey])

  const setNextSurveyAsCurrent = (finishedSurvey: string) => {
    const finishedIndex =
      AllSurveys[props.surveyIterationId].indexOf(finishedSurvey)
    setCurrentSurvey(AllSurveys[props.surveyIterationId][finishedIndex + 1])
  }

  const completeSurvey = (surveyName: string) => {
    setStatus((prevData) => {
      const newState: Record<string, boolean> = { ...prevData }
      newState[surveyName] = true
      return newState
    })
    setNextSurveyAsCurrent(surveyName)
    setCurrentSurveyNumber(currentSurveyNumber + 1)
  }

  return (
    <SurveyContext.Provider
      value={{
        status,
        completeSurvey,
        currentSurvey,
        currentSurveyNumber,
        currentStepNumber,
        setCurrentStepNumber,
        setTotalSteps,
        totalSteps
      }}
    >
      {props.children}
    </SurveyContext.Provider>
  )
}
