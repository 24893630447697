import { authenticityToken } from "../../util/authenticityTokenHelper"
import Button from "../Button"
import HovedStadenLogo from "~/images/region-hovedstaden.svg"
import { IndexPageText } from "~/const/globalStrings"
import { Question } from "~/types/questionTypes"
import SurveyStepHeader from "../SurveyStepHeader"
import { SignUpInput } from "../SurveyStep/SignUpStep"
import { useState } from "react"
import { submitSignup } from "~/util/submitSignup"
import Icon from "../Icon"
import "./index.scss"
import SignUpSuccess from "./SignUpSuccess"

export interface SignUpProps {
  landingBackButtonUrl: string
}

export default function SignUp({ landingBackButtonUrl }: SignUpProps) {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [submitBtnText, setSubmitBtnText] = useState(
    IndexPageText.signup.submit
  )
  const [values, setValues] = useState({
    /* eslint-disable camelcase */
    authenticity_token: authenticityToken(),
    first_name: "",
    last_name: "",
    phone_number: "",
    region: "",
    atrial_fibrillation: "",
    sleep_apnea: ""
    /* eslint-enable camelcase */
  })

  const updateFields = (newData: Record<string, string>) => {
    setValues((prevData) => ({ ...prevData, ...newData }))
  }

  const question: Question = {
    branchingLogic: "",
    fieldLabel: "",
    fieldName: "",
    fieldType: "signup",
    sectionHeader: IndexPageText.signup.infoText,
    fieldNote: "",
    formName: "",
    fieldAnnotation: "",
    textValidationTypeOrShowSliderNumber: ""
  }

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    let success = true
    await submitSignup(values).catch(() => {
      alert("Something went wrong. Please try again.")
      success = false
    })
    if (success) {
      setIsSubmitted(true)
      setSubmitBtnText(IndexPageText.signup.btnSubmitted)
    }
  }

  const handleArrowClick = () => {
    window.location.href = landingBackButtonUrl
  }

  return (
    <div className="SignUp" data-testid="signup">
      <form method="post" onSubmit={onSubmitHandler} name="survey">
        <input
          type="hidden"
          name="authenticity_token"
          value={authenticityToken()}
        />
        <div className="question-responsive">
          <SurveyStepHeader question={question} />
          <div className={`question-container grow`}>
            <img
              src={HovedStadenLogo}
              alt="Region Hovedstaden"
              className="hidden lg:block self-end"
            />
            <div className="question-group">
              {isSubmitted ? (
                <SignUpSuccess />
              ) : (
                <>
                  <SignUpInput values={values} changeValues={updateFields} />
                  <div className="button-group">
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={handleArrowClick}
                    >
                      <Icon
                        name="arrowLeft"
                        className="w-4 h-4"
                        testId="arrowLeft"
                      />
                    </Button>
                    <Button
                      type="submit"
                      variant="basic"
                      disabled={isSubmitted}
                    >
                      {submitBtnText}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
