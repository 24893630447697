import { toCamelCase } from "./toCamelCase"

function parseFieldAnnotations(annotation: string): {
  [key: string]: boolean | string
} {
  const result: { [key: string]: boolean | string } = {}
  const regex = /@(\w+)(?:\((.*?)\)|=(".*?"|'.*?'|.*?))?(?:\s|$)/g
  let match

  while ((match = regex.exec(annotation)) !== null) {
    const key = toCamelCase(match[1].toLowerCase())
    const param1 = match[2]
    const param2 = match[3]

    if (param1 !== undefined) {
      result[key] = param1
    } else if (param2 !== undefined) {
      result[key] = param2
    } else {
      result[key] = true
    }
  }

  return result
}

export default parseFieldAnnotations
