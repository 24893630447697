import "./index.scss"

import close from "~/images/x.svg"

export interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  open: boolean
  onClose: () => void
  title: string
}

export default function Modal({
  open,
  onClose,
  children,
  title
}: ModalProps): JSX.Element {
  return (
    // Backdrop
    <div
      onClick={onClose}
      className={`backdrop ${open ? "visible bg-black/20" : "invisible"}`}
      data-testid="backdrop"
    >
      {/* modal */}
      <div
        onClick={(e) => e.stopPropagation()}
        className={`modal ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <div className="modal-header">
          <h2>{title}</h2>
          <button onClick={onClose} className="btn-close">
            <img src={close} alt="close" className="w-6" />
          </button>
        </div>
        {children}
      </div>
    </div>
  )
}
