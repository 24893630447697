import { IErrorInfo } from "../types/errorTypes"
import { CustomError } from "../types/errorTypes"
import { getRollbarConfig } from "./rollbarConfig"
import Rollbar from "rollbar"

const reportErrorToRollbar = (error: CustomError, info?: IErrorInfo) => {
  const rollbar = new Rollbar(getRollbarConfig())

  rollbar.error(error.message, error, info)
}

export default reportErrorToRollbar
