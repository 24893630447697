import { useContext } from "react"
import { SurveyContext } from "../Main/SurveyContext"
import { NumberWithWords } from "~/const/globalStrings"

const SurveyIntroText = () => {
  const context = useContext(SurveyContext)
  if (!context) return <></>

  const numberOfSurveysInSurvey = Object.keys(context?.status).length
  const halfOfSurveys = Math.floor(numberOfSurveysInSurvey / 2)
  const currentSurveyIndex = context.currentSurveyNumber - 1

  const renderText = () => {
    if (
      currentSurveyIndex === numberOfSurveysInSurvey ||
      currentSurveyIndex === -1
    ) {
      return <p>Tak. Du er nu klar til at fortsætte til appen.</p>
    } else if (currentSurveyIndex === 0) {
      return (
        <p>
          Besvar venligst de {NumberWithWords[numberOfSurveysInSurvey]}{" "}
          spørgeskemaer.
        </p>
      )
    } else if (currentSurveyIndex < halfOfSurveys) {
      return (
        <p>
          Tak. Du mangler nu{" "}
          {NumberWithWords[numberOfSurveysInSurvey - currentSurveyIndex]}{" "}
          spørgeskemaer.
        </p>
      )
    } else if (currentSurveyIndex === halfOfSurveys) {
      return <p>Du er nu halvvejs igennem.</p>
    } else {
      return (
        <p>
          Nu mangler du{" "}
          {NumberWithWords[numberOfSurveysInSurvey - currentSurveyIndex]}{" "}
          spørgeskema.
        </p>
      )
    }
  }

  return (
    <div className="text-3xl text-neutral-900 font-serif">{renderText()}</div>
  )
}

export default SurveyIntroText
