export interface BranchingLogic {
  variableName: string | null
  operator: string | null
  rightValue: string | null
}

export function parseBranchingLogic(expression: string): BranchingLogic {
  // The expresion will be like `[afss_10] = "7"`
  // We make 3 groups (matches).
  // The first group is the variable name (text betweeen [])
  // The second group is the operator (either <> or =) preceded by a possible whitespace
  // The third group is the right value (number between quotes)
  const regex = /\[(\w+)\]\s*(<>|=)\s*"?\s*([0-9]*)\s*"?/
  const match = expression.match(regex)

  return {
    variableName: match && match[1],
    operator: match && match[2],
    rightValue: match && match[3]
  }
}
