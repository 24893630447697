import { useContext, useEffect, useState } from "react"
import { submitSurvey } from "../../util/submitSurvey"

import { Data } from "../../types/dataTypes"
import { Question } from "../../types/questionTypes"
import { authenticityToken } from "../../util/authenticityTokenHelper"
import useMultiStepForm from "../../util/useMultiStepForm"
import SurveyStep from "../SurveyStep"
import Button from "../Button"
import { filterAndFillDateQuestions } from "~/util/filterAndFillDateQuestions"
import { SurveyContext } from "../Main/SurveyContext"
import { SurveyCompletionEstimates } from "~/const/globalStrings"
import moment from "moment-timezone"
import { filterSupportedFieldTypes } from "../../util/filterSupportedFieldTypes"
import { shouldIncludeBranchQuestion } from "../../util/shouldIncludeBranchQuestion"
import SurveyExplainer from "../SurveyExplainer"
import Icon from "../Icon"
import SurveyQuestion from "../SurveyQuestion"

export interface SurveyComponentProps
  extends React.HTMLAttributes<HTMLDivElement> {
  name: string
  onSurveySubmit: () => void
  surveyMetadata: Array<Question>
}

export default function SurveyComponent({
  name,
  onSurveySubmit,
  surveyMetadata,
  ...rest
}: SurveyComponentProps): JSX.Element {
  const [token] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get("token") || ""
  })
  const [submitDisabled, setsubmitDisabled] = useState(false)
  const [noDateQuestionsSurvey, dateAnswers] =
    filterAndFillDateQuestions(surveyMetadata)

  const explainerMetaData: Question = {
    branchingLogic: "",
    fieldLabel: SurveyCompletionEstimates[name].name,
    fieldName: name,
    fieldType: "explainer",
    sectionHeader: "",
    fieldNote: "",
    formName: name,
    fieldAnnotation: "",
    textValidationTypeOrShowSliderNumber: "",
    textValidationMax: "",
    textValidationMin: ""
  }

  const [filteredSurvey, setFilteredSurvey] = useState([
    explainerMetaData
  ] as Array<Question>)

  const context = useContext(SurveyContext)

  const [data, setData] = useState({
    // eslint-disable-next-line camelcase
    authenticity_token: authenticityToken(),
    token: token,
    // eslint-disable-next-line camelcase
    survey_name: name,
    ...dateAnswers
  })

  const updateFields = (newData: Data) => {
    setData((prevData) => ({ ...prevData, ...newData }))
  }

  useEffect(() => {
    const filteredQuestions = filterSupportedFieldTypes(noDateQuestionsSurvey)
    setFilteredSurvey((prevFilteredSurvey) =>
      prevFilteredSurvey.concat(filteredQuestions)
    )
  }, [])

  const surveyQuestions = filteredSurvey.filter((question) =>
    shouldIncludeBranchQuestion(data, question)
  )

  const surveySteps: Array<JSX.Element> = surveyQuestions.map(
    (question: Question, index: number) => {
      return (
        <SurveyStep
          key={index}
          data={data}
          question={question}
          updateFields={updateFields}
        />
      )
    }
  )

  const {
    currentStepIndex,
    currentStep,
    isFirstStep,
    previousStep,
    nextStep,
    isLastStep,
    totalSteps
  } = useMultiStepForm(surveySteps)

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setsubmitDisabled(true)

    if (!isLastStep) {
      nextStep()
      setsubmitDisabled(false)
    } else {
      const autoTimestampQuestion = noDateQuestionsSurvey.find(
        (question) => question.fieldAnnotation === "auto_timestamp"
      )
      const timestamp = autoTimestampQuestion
        ? {
            [autoTimestampQuestion.fieldName]: moment()
              .tz("Europe/Copenhagen")
              .format()
          }
        : {}

      setData((prevData) => {
        const dataWithTimestamp = { ...prevData, ...timestamp }
        submitSurvey(dataWithTimestamp)
          .then(() => {
            context?.completeSurvey(name)
            onSurveySubmit()
          })
          .catch(() => {
            alert("Something went wrong. Please try again.")
          })
          .finally(() => {
            setsubmitDisabled(false)
          })

        return dataWithTimestamp
      })
    }
  }

  useEffect(() => {
    if (context) {
      context.setCurrentStepNumber(currentStepIndex)
      context.setTotalSteps(totalSteps)
    }
  }, [currentStepIndex, totalSteps, context])

  const currentQuestion = surveyQuestions[currentStepIndex]

  return (
    <div {...rest} className="SurveyComponent">
      <form
        method="post"
        onSubmit={onSubmitHandler}
        name="survey"
        className="bg-neutral-200 lg:bg-neutral-100 w-full grid min-h-full"
      >
        <input
          type="hidden"
          name="authenticity_token"
          value={authenticityToken()}
        />

        {isFirstStep ? (
          <SurveyExplainer
            formName={currentQuestion.formName}
          ></SurveyExplainer>
        ) : (
          <div className="flex flex-col justify-end px-[6px] lg:flex-row pb-14 lg:pb-0 lg:px-0 lg:items-center ">
            <SurveyQuestion currentQuestion={currentQuestion} />

            <div className="bg-neutral-50 lg:bg-neutral-200 p-4 rounded-3xl lg:rounded-none w-full flex justify-center items-center lg:h-full">
              <div className="w-full lg:w-[660px] bg-neutral-50 rounded-3xl lg:p-4 h-fit">
                <div className="text-neutral-700">{currentStep}</div>

                <div className="w-full flex gap-2.5 button-group">
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={previousStep}
                    id="previousStepButton"
                    className="ring-neutral-50"
                  >
                    <Icon
                      name="arrowLeft"
                      className="w-6 h-6 text-neutral-900"
                      testId="arrowLeft"
                    />
                  </Button>
                  <Button
                    type="submit"
                    variant="basic"
                    disabled={submitDisabled}
                    className="w-full ring-neutral-50"
                  >
                    {isLastStep ? (
                      "Indsend besvarelse"
                    ) : (
                      <span className="flex justify-between">
                        Næste spørgsmål
                        <Icon
                          name="arrowRight"
                          className="w-6 h-6 text-inherit"
                        />
                      </span>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  )
}
