import { HTMLTagRenderer } from "~/util/HTMLTagRenderer"
import { Explainers } from "~/const/globalStrings"
import Button from "../Button"
import LightBulbIcon from "~/images/light-bulb.svg"

export interface SurveyExplainerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  formName: string
}
export default function SurveyExplainer(
  props: SurveyExplainerProps
): JSX.Element {
  const { intro, instructions, tip } = Explainers[props.formName]

  return (
    <div className="flex flex-col items-center lg:items-start justify-end lg:justify-self-center lg:self-center px-4 pb-14 pt-8 gap-8 lg:w-[660px]">
      <div className="flex flex-col gap-8 self-center text-neutral-900 pt-24">
        <HTMLTagRenderer
          string={intro}
          className="font-serif text-2xl font-normal"
        />
        <HTMLTagRenderer
          string={instructions}
          className="text-base font-sans"
        />
        {tip && (
          <div className="flex gap-2 items-center p-2 bg-neutral-300 rounded-2xl">
            <img src={LightBulbIcon} alt="Question Icon" />
            <p className="text-sm">{tip}</p>
          </div>
        )}
      </div>
      <Button type="submit" variant="basic" className="w-64 lg:w-80 py-4">
        Fortsæt
      </Button>
    </div>
  )
}
