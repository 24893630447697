import { Data } from "~/types/dataTypes"
import { TextQuestion } from "~/types/questionTypes"

type TextareaInputProps = {
  data: Data
  question: TextQuestion
  updateFields: (newData: Data) => void
  className?: string
}

export const TextareaInput = (props: TextareaInputProps): JSX.Element => {
  const {
    question: { fieldName },
    data,
    updateFields,
    className
  } = props
  data[fieldName] = data[fieldName] || ""

  return (
    <div className={className}>
      <p>Beskriv</p>
      <textarea
        className="TextareaInput"
        autoFocus
        name={fieldName}
        value={data[fieldName]}
        onChange={(event) => {
          updateFields({ [fieldName]: event.target.value })
        }}
      />
    </div>
  )
}
