import IconPath, { IconType } from "./IconPaths"

export type IconProps = {
  name: IconType
  title?: string
  viewBox?: string
  testId?: string
  className?: string
}

function Icon({ name, title, viewBox, testId, className }: IconProps) {
  if (name === "stopWatchSimple") viewBox = "0 0 16 16"
  if (name === "check") viewBox = "0 0 20 20"
  if (name === "video") viewBox = "0 0 25 24"
  if (name === "email") viewBox = "0 0 25 24"
  if (name === "iphoneShake") viewBox = "0 0 25 24"
  if (name === "link") viewBox = "0 0 16 16"
  if (name === "call") viewBox = "0 0 16 16"
  if (name === "dotsHorizontal") viewBox = "0 0 16 16"

  return (
    <svg
      data-testid={testId}
      viewBox={viewBox ?? "0 0 24 24"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? "w-6 h-6"}
    >
      {title && <title>{title}</title>}
      <IconPath name={name} />
    </svg>
  )
}

export default Icon
