import ChecklistCompleteLogo from "~/images/checklistComplete.svg"
import { IndexPageText } from "~/const/globalStrings"

const SignUpSuccess = () => {
  return (
    <div className="flex flex-col items-center lg:items-start">
      <img src={ChecklistCompleteLogo} alt="Book Logo" className="w-80 my-4" />
      <p>{IndexPageText.signup.done}</p>
    </div>
  )
}

export default SignUpSuccess
