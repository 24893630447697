import classNames from "classnames"

export enum InputSize {
  Small = "sm",
  Medium = "md",
  Large = "lg"
}

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size"> {
  label?: string
}

export default function Input(props: InputProps): JSX.Element {
  const { className, children, label, name, ...rest } = props

  const usedClassName = classNames(
    " rounded-2xl outline-none px-5 py-4 w-full border-[1px] border-neutral-300 focus:border-primary-400 focus:opacity-100 focus:border-2 active:border-2 active:border-primary-400  active:opacity-100 placeholder-neutral-700 text-black ",
    className
  )

  return (
    <div className="flex flex-col gap-1.5">
      <label htmlFor={name} className="inline-block w-full">
        {label}
      </label>
      <input className={usedClassName} id={name} name={name} {...rest} />
      {children}
    </div>
  )
}
