import Button from "../Button"
import { SurveyProgressCards } from "../SurveyProgressCards"
import { useContext } from "react"
import { SurveyContext } from "../Main/SurveyContext"
import SurveyIntroText from "../SurveyIntroText"
import Icon from "../Icon"

export default function OnboardingOverview({
  onStart
}: {
  onStart: VoidFunction
}): JSX.Element {
  const context = useContext(SurveyContext)

  if (!context) return <></>

  const handleNext = () => {
    if (
      context.currentSurveyNumber === 0 ||
      context.currentSurveyNumber === numberOfSurveys + 1
    ) {
      window.ReactNativeWebView.postMessage("navigateToMobileApp")
    } else {
      onStart()
    }
  }
  const currentSurveyNumber = context.currentSurveyNumber
  const statusObject = context.status
  const numberOfSurveys = Object.keys(statusObject).length

  return (
    <div className="flex flex-col gap-5 pt-8 pb-24 px-1.5 bg-neutral-200 items-center lg:h-full justify-center">
      <div className="flex flex-col gap-5">
        <p className="text-neutral-700 font-semibold text-center">
          Spørgeskemaer
        </p>

        <div className="flex flex-col gap-6 px-4">
          <SurveyIntroText />

          <div className="flex gap-2 items-center p-2 bg-neutral-300 rounded-2xl text-neutral-900">
            <div>
              <Icon name="infoCircle" className="w-6 h-6" />
            </div>
            <p className="text-sm">
              Spørgeskemaet gemmes først, når alle spørgsmål er udfyldt. Hvis du
              lukker spørgeskemaet undervejs, vil dine svar ikke blive gemt.
            </p>
          </div>
        </div>
        <SurveyProgressCards />
      </div>

      <Button type="submit" variant="basic" onClick={handleNext}>
        {context.currentSurveyNumber > numberOfSurveys ||
        context.currentSurveyNumber === 0
          ? "Fortsæt til app’en"
          : `Besvar spørgerskema ${currentSurveyNumber}`}
      </Button>
    </div>
  )
}
