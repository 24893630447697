import { HTMLTagRenderer } from "~/util/HTMLTagRenderer"
import "./index.scss"

export interface RadioButtonProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type"> {
  label: string
}

export default function RadioButton(props: RadioButtonProps): JSX.Element {
  const { label, ...restProps } = props

  return (
    <label className="RadioButton">
      <input
        type="radio"
        checked
        className="RadioButton__input"
        {...restProps}
      />
      <HTMLTagRenderer string={label} />
    </label>
  )
}
