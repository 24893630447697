import "./index.scss"

import AppStore from "~/images/appstore.png"
import BeckettLogo from "~/images/beckettfonden.png"
import Button from "../Button"
import { ButtonSize } from "../Button"
import { capitalize, replaceUnderscores } from "~/util/stringUtils"
import GooglePlay from "~/images/googleplay.png"
import HelsefondenLogo from "~/images/helsefonden.png"
import HjerteforeningenLogo from "~/images/hjerteforeningen.png"
import HovedStadenLogo from "~/images/region-hovedstaden.svg"
import { HTMLTagRenderer } from "~/util/HTMLTagRenderer"
import { IndexPageText } from "~/const/globalStrings"
import Modal from "../Modal"
import SophusLogo from "~/images/sophus.png"
import { Fragment, useState } from "react"

export interface VideoProp {
  className?: string
}

const Video = ({ className }: VideoProp) => {
  return (
    <iframe
      className={className}
      width="315"
      height="560"
      src={IndexPageText.about.videoLink}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    ></iframe>
  )
}

export interface GroupedFiles {
  [key: string]: string[]
}

export interface DocumentsProps {
  files: GroupedFiles
}

const Documents = ({ files }: DocumentsProps) => {
  const appLinks = {
    Fibricheck: {
      appStore: "https://apps.apple.com/dk/app/fibricheck/id1102327473?l=da",
      googlePlay:
        "https://play.google.com/store/apps/details?id=com.qompium.fibricheck&hl=en"
    },
    NightOwl: {
      appStore:
        "https://apps.apple.com/dk/app/nightowl-companion/id1397382074?l=da",
      googlePlay:
        "https://play.google.com/store/apps/details?id=com.ectosense.nightowl&pcampaignid=web_share"
    },
    SENSMotion: {
      appStore: "https://apps.apple.com/dk/app/sens-motion/id998363471?l=da",
      googlePlay:
        "https://play.google.com/store/apps/details?id=dk.sens.sensmotion&pcampaignid=web_share"
    }
  }

  const documents = Object.entries(files).map(([group, fileList]) => {
    return (
      <Fragment key={group}>
        <h4 className="p-3">{capitalize(group)}:</h4>
        <div className="link-list">
          {fileList.map((value, index) => {
            return (
              <div key={index} className="link-list-element">
                <a href={`/files/${value}`}>{replaceUnderscores(value)}</a>
              </div>
            )
          })}
        </div>
      </Fragment>
    )
  })

  const apps = (
    <div className="flex flex-col items-center">
      {Object.entries(appLinks).map(([app, stores]) => {
        return (
          <div key={app}>
            <h4 className="p-3">{app}:</h4>
            <div className="p-3 flex space-x-4">
              <div>
                <a href={stores.appStore}>
                  <img
                    src={AppStore}
                    alt={`${app} on app store`}
                    className="w-32"
                  />
                </a>
              </div>
              <div>
                <a href={stores.googlePlay}>
                  <img
                    src={GooglePlay}
                    alt={`${app} on google play`}
                    className="w-32"
                  />
                </a>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )

  const [appPage, setAppPage] = useState(false)

  return (
    <div className="modal-body flex flex-col">
      {appPage ? apps : documents}
      <Button
        className="mt-6 w-2/3 self-center"
        type="button"
        variant={appPage ? "secondary" : "basic"}
        size={ButtonSize.ExtraLarge}
        onClick={() => setAppPage(!appPage)}
      >
        <span>{appPage ? "Dokumenter" : "Apps"}</span>
      </Button>
    </div>
  )
}

export interface LandingFrontProps
  extends React.HTMLAttributes<HTMLDivElement> {
  signUp: () => void
  files: GroupedFiles
}

export default function LandingFront({
  signUp,
  files
}: LandingFrontProps): JSX.Element {
  const [viewVideo, setViewVideo] = useState(false)
  const [viewDocuments, setViewDocuments] = useState(false)
  const toggleVideoModal = () => {
    setViewVideo(!viewVideo)
  }
  const toggleDocumentsModal = () => {
    setViewDocuments(!viewDocuments)
  }

  return (
    <div
      className="LandingFront h-full w-full flex flex-col lg:flex-row"
      data-testid="landing"
    >
      <div className="p-8 flex-grow overflow-y-auto flex flex-col justify-between lg:p-16 lg:w-2/3 gap-8">
        <div>
          <img src={HovedStadenLogo} alt="Region Hovedstaden" />
        </div>
        <div className="space-y-3">
          <h2>{IndexPageText.about.header}</h2>
          <h3>{IndexPageText.about.subheader}</h3>
          <HTMLTagRenderer string={IndexPageText.about.bulk} />
        </div>
        <div>
          <p>
            <i>{IndexPageText.about.support}</i>
          </p>
          <div className="flex flex-row space-x-5 lg:space-x-20 p-5 items-center">
            <div>
              <img
                src={HjerteforeningenLogo}
                alt="Hjerteforeningen"
                className="w-32"
              />
            </div>
            <div>
              <img src={HelsefondenLogo} alt="Helsefonden" className="w-32" />
            </div>
            <div>
              <img src={BeckettLogo} alt="Beckett Fonden" className="w-32" />
            </div>
            <div>
              <img
                src={SophusLogo}
                alt="Snedkermester Sophus Jacobsen og hustru Astrid Jacobsens Fond igennem Hjerteforeningen"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-neutral-200 flex flex-col justify-evenly py-8 text-center items-center lg:py-40 lg:w-1/3">
        <div className="Video">
          <Video className="hidden lg:block" />
          <a className="block lg:hidden" onClick={toggleVideoModal}>
            {IndexPageText.buttons.video}
          </a>
        </div>
        <div className="button-group">
          <Button
            type="button"
            variant="secondary"
            size={ButtonSize.ExtraLarge}
            onClick={toggleDocumentsModal}
          >
            <span>{IndexPageText.buttons.documents}</span>
          </Button>
          <Button
            type="button"
            variant="basic"
            size={ButtonSize.ExtraLarge}
            onClick={() => signUp()}
          >
            <span>{IndexPageText.buttons.signup}</span>
          </Button>
        </div>
      </div>
      <Modal
        open={viewVideo}
        onClose={toggleVideoModal}
        title="Introduktions Video"
      >
        <div className="flex justify-center p-3">
          <Video />
        </div>
      </Modal>
      <Modal
        open={viewDocuments}
        onClose={toggleDocumentsModal}
        title="Information"
      >
        <Documents files={files} />
      </Modal>
    </div>
  )
}
