import { Question } from "../types/questionTypes"
import reportErrorsToRollbar from "./reportErrorToRollbar"
import { UnsupportedFieldTypeError } from "../errors/UnsupportedFieldTypeError"

const SUPPORTED_FIELD_TYPES = [
  "text",
  "radio",
  "descriptive",
  "notes",
  "slider",
  "explainer"
]

export const filterSupportedFieldTypes = (questions: Question[]) => {
  return questions.filter((question) => {
    const isValidType = SUPPORTED_FIELD_TYPES.includes(question.fieldType)

    if (!isValidType) {
      reportErrorsToRollbar(new UnsupportedFieldTypeError(question))
    }

    return (
      isValidType &&
      question.fieldAnnotation !== "auto_timestamp" &&
      question.fieldName !== "ehlq_forklar"
    )
  })
}
