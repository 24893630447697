import "./index.scss"

import CheckmarkValid from "~/images/checkmark-valid.svg"
import CheckmarkFocused from "~/images/checkmark-invalid.svg"
import Input from "~/components/Input"
import RadioButton from "~/components/RadioButton"

export interface SignUpValues {
  first_name: string
  last_name: string
  phone_number: string
  region: string
  atrial_fibrillation: string
  sleep_apnea: string
}

export interface SignUpInputProps extends React.HTMLAttributes<HTMLDivElement> {
  values: SignUpValues
  changeValues: (newData: Record<string, string>) => void
}

export const SignUpInput = ({
  values,
  changeValues
}: SignUpInputProps): JSX.Element => {
  const trimPhone = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.target.value = event.target.value.replace(/\s/g, "")
    changeValues({ [event.target.name]: event.target.value })
  }

  return (
    <div className="signup" data-testid="signup-input">
      <div className="flex flex-col lg:w-1/2">
        <div className="flex flex-row mb-2">
          <div className="input-container w-1/2 mr-4">
            <Input
              id="first_name"
              name="first_name"
              type="text"
              placeholder="F"
              defaultValue={values["first_name"]}
              onChange={(event) => {
                changeValues({ [event.target.name]: event.target.value })
              }}
              className="peer placeholder-transparent"
              autoComplete="given-name"
              pattern="^[A-ZÆØÅa-zæøå ,.'\-]+$"
              required
              label="Fornavn"
            />
          </div>
          <div className="input-container w-1/2">
            <Input
              id="last_name"
              name="last_name"
              type="text"
              placeholder="L"
              defaultValue={values["last_name"]}
              onChange={(event) => {
                changeValues({ [event.target.name]: event.target.value })
              }}
              className="peer placeholder-transparent"
              autoComplete="family-name"
              pattern="^[A-ZÆØÅa-zæøå ,.'\-]+$"
              required
              label="Efternavn"
            />
          </div>
        </div>
        <div className="flex flex-col mb-2">
          <Input
            id="phone_number"
            name="phone_number"
            type="tel"
            placeholder="P"
            defaultValue={values["phone_number"]}
            className="peer placeholder-transparent"
            autoComplete="tel-national"
            pattern="^[0-9]{8}$"
            onChange={trimPhone}
            required
            label="Telefonnummer"
          />
        </div>
        <div className="col-span-2">
          <div id="Input__container" className="relative">
            <select
              name="region"
              id="region"
              className="Input"
              style={{ height: "58px", padding: "0px 20px" }}
              defaultValue={values["region"]}
              onChange={(event) => {
                changeValues({ [event.target.name]: event.target.value })
              }}
              required
            >
              <option value="" disabled>
                Hvilken region bor du i?
              </option>
              <option value="1">Region Hovedstaden</option>
              <option value="2">Region Midtjylland</option>
              <option value="3">Region Nordjylland</option>
              <option value="4">Region Sjælland</option>
              <option value="5">Region Syddanmark</option>
            </select>
            <img
              src={CheckmarkValid}
              alt="checkmark-valid"
              className="Input__checkmark Input__checkmark-valid"
            />
            <img
              src={CheckmarkFocused}
              alt="checkmark-focused"
              className="Input__checkmark Input__checkmark-focused"
            />
            <div className="my-4">
              <label htmlFor="atrial_fibrillation">
                Er du nogensinde blevet diagnosticeret med hjerteflimmer?
              </label>
            </div>
            <div className="my-4">
              <RadioButton
                id="atrial_fibrillation"
                required
                checked={values["atrial_fibrillation"] === "1"}
                key="atrial_fibrillation_1"
                name="atrial_fibrillation"
                label="Ja"
                value="1"
                onChange={(event) => {
                  changeValues({ [event.target.name]: event.target.value })
                }}
              />
            </div>
            <div className="my-4">
              <RadioButton
                required
                checked={values["atrial_fibrillation"] === "2"}
                key="atrial_fibrillation_2"
                name="atrial_fibrillation"
                label="Nej"
                value="2"
                onChange={(event) => {
                  changeValues({ [event.target.name]: event.target.value })
                }}
              />
            </div>
            <RadioButton
              required
              checked={values["atrial_fibrillation"] === "3"}
              key="atrial_fibrillation_3"
              name="atrial_fibrillation"
              label="Ved ikke"
              value="3"
              onChange={(event) => {
                changeValues({ [event.target.name]: event.target.value })
              }}
            />
            <div className="my-4">
              <label htmlFor="sleep_apnea">
                Er du nogensinde blevet undersøgt for søvnapnø?
              </label>
            </div>
            <div className="my-3">
              <RadioButton
                id="sleep_apnea"
                required
                checked={values["sleep_apnea"] === "1"}
                key="sleep_apnea_1"
                name="sleep_apnea"
                label="Ja"
                value="1"
                onChange={(event) => {
                  changeValues({ [event.target.name]: event.target.value })
                }}
              />
            </div>
            <RadioButton
              required
              checked={values["sleep_apnea"] === "2"}
              key="sleep_apnea_2"
              name="sleep_apnea"
              label="Nej"
              value="2"
              onChange={(event) => {
                changeValues({ [event.target.name]: event.target.value })
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
