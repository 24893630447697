import { useEffect, useRef } from "react"
import MitIdImage from "~/images/mitid.png"
export interface MitIdProps {
  token?: string
}
export default function MitId({ token }: MitIdProps) {
  const formRef = useRef<HTMLFormElement>(null)

  const submitForm = () => {
    if (token && window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(token))
    } else {
      const csrfTokenElement = document.querySelector(
        'meta[name="csrf-token"]'
      ) as HTMLMetaElement

      if (csrfTokenElement && formRef.current) {
        const csrfToken = csrfTokenElement.getAttribute("content")

        /* istanbul ignore next */
        if (csrfToken) {
          const csrfInput = document.createElement("input")
          csrfInput.setAttribute("type", "hidden")
          csrfInput.setAttribute("name", "authenticity_token")
          csrfInput.setAttribute("value", csrfToken)
          formRef.current.appendChild(csrfInput)
          formRef.current.submit()
        }
      }
    }
  }

  useEffect(() => {
    submitForm()
  }, [])

  return (
    <form
      ref={formRef}
      action="/auth/saml/sikker-login-dk"
      method="post"
      role="form"
      className="w-full flex justify-center items-center"
      data-testid="mitid-form"
    >
      <div className="flex flex-col py-16 items-center">
        <p className="text-2xl font-bold mb-5">Vælg log ind metode</p>
        <button
          type="submit"
          className="px-5 py-16 border border-info-300 rounded-3xl hover:opacity-80"
        >
          <img src={MitIdImage} alt="MitId" className="w-32" />
        </button>
      </div>
    </form>
  )
}
