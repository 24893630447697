import DataTable, { TableColumn } from "react-data-table-component"

interface ParticipantRow {
  recordId: string
  cprNumber: string
  firstName: string
  lastName: string
  email: string
  createdAt: string
  dateOfBirth: string
  consent: string
}

interface ParticipantDatatableProps {
  participantData: ParticipantRow[]
}

export default function ParticipantDatatable({
  participantData
}: ParticipantDatatableProps): JSX.Element {
  const columns: TableColumn<ParticipantRow>[] = [
    {
      name: "Record Id",
      selector: (row) => row.recordId,
      sortable: true,
      grow: 3
    },
    {
      name: "CPR",
      selector: (row) => row.cprNumber,
      sortable: true
    },
    {
      name: "Name",
      selector: (row) => row.firstName,
      sortable: true
    },
    {
      name: "Lastname",
      selector: (row) => row.lastName,
      sortable: true
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true
    },
    {
      name: "Date of birth",
      selector: (row) => row.dateOfBirth,
      sortable: true
    },
    {
      name: "Consent",
      selector: (row) => row.consent,
      sortable: true
    },
    {
      name: "Created at",
      selector: (row) => row.createdAt,
      sortable: true
    },
    {
      cell: (row) => <a href={`/participants/${row.recordId}`}>View</a>,
      allowOverflow: true,
      button: true
    }
  ]

  return <DataTable columns={columns} data={participantData} pagination />
}
