import SignUp from "../SignUp"
import { useMultiPage } from "../../util/useMultiPage"

interface LandingComponentProps {
  landingBackButtonUrl: string
}

export default function Landing({
  landingBackButtonUrl
}: LandingComponentProps): JSX.Element {
  const allPages: Array<JSX.Element> = [
    <SignUp landingBackButtonUrl={landingBackButtonUrl} />
  ]

  const { currentPage } = useMultiPage(allPages)

  return <div className="main">{currentPage}</div>
}
