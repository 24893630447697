import DOMPurify from "dompurify"
import { HTMLProps } from "react"

export type HTMLTagRendererProps = {
  string: string
  className?: string
} & HTMLProps<HTMLDivElement>

const allowedTags: string[] = ["b", "em", "strong", "i", "br"]

export const HTMLTagRenderer = ({
  string,
  className,
  ...restProps
}: HTMLTagRendererProps) => {
  const cleanHTML = DOMPurify.sanitize(string, { ALLOWED_TAGS: allowedTags })

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: cleanHTML }}
      {...restProps}
    />
  )
}
