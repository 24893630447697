import { useEffect, useRef } from "react"
import {
  Question,
  RadioQuestion,
  SliderQuestion,
  TextQuestion
} from "../../types/questionTypes"
import { Data } from "../../types/dataTypes"
import { TextareaInput } from "./TextareaInput"
import { RadioButtonGroup } from "./RadioButtonGroup"
import { SliderInput } from "./SliderInput"
import parseFieldAnnotations from "~/util/parseFieldAnnotations"
import { TextInput } from "./TextInput"

export interface SurveyStepProps {
  data: Data
  question: Question
  updateFields: (newData: Data) => void
}

export default function SurveyStep({
  data,
  question,
  updateFields
}: SurveyStepProps): JSX.Element {
  const scrollRef = useRef<null | HTMLDivElement>(null)

  useEffect(
    () =>
      scrollRef?.current?.parentElement?.parentElement?.scroll({
        top: 0,
        behavior: "smooth"
      }),
    []
  )

  const { fieldType, fieldLabel, fieldAnnotation, fieldNote } = question

  const parsedFieldAnnotation = parseFieldAnnotations(fieldAnnotation)
  let placeholder = parsedFieldAnnotation.placeholder as string
  if (placeholder) {
    placeholder = placeholder.replace(/['"]/g, "")
  }

  const labelExtraString = " (" + fieldNote?.toLowerCase() + ")"
  const inputLabel = fieldNote ? fieldLabel + labelExtraString : fieldLabel

  const renderQuestion = () => {
    switch (fieldType) {
      case "radio":
        return (
          <RadioButtonGroup
            data={data}
            question={question as RadioQuestion}
            updateFields={updateFields}
            className="mb-8"
          />
        )
      case "text":
        return (
          <TextInput
            data={data}
            question={question as RadioQuestion}
            updateFields={updateFields}
            className="mb-8"
            inputLabel={inputLabel}
          />
        )
      case "notes":
        return (
          <TextareaInput
            data={data}
            question={question as TextQuestion}
            updateFields={updateFields}
            className="mb-6"
          />
        )
      case "slider":
        return (
          <SliderInput
            data={data}
            question={question as SliderQuestion}
            updateFields={updateFields}
          />
        )
      case "descriptive":
        return (
          <p className="mb-9 lg:p-8 lg:mb-6">
            Den næste række spørgsmål vil omhandle det overordnede spørgsmål,
            fortsættes på næste side.
          </p>
        )
      default:
        return <div></div>
    }
  }

  return (
    <>
      <div ref={scrollRef} />
      {renderQuestion()}
    </>
  )
}
