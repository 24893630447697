import { Question } from "~/types/questionTypes"
import SurveyProgress from "../SurveyProgress"
import { HTMLTagRenderer } from "~/util/HTMLTagRenderer"
import neckMeasureIcon from "~/images/neckMeasure.svg"

type Props = {
  currentQuestion: Question
  className?: string
}

const SurveyQuestion = (props: Props) => {
  const { currentQuestion } = props

  return (
    <aside className="flex flex-col justify-between gap-8 h-full py-8 px-3 lg:justify-center lg:bg-neutral-100 lg:min-w-[340px] lg:max-w-[340px] xl:min-w-[440px] xl:max-w-[440px] lg:px-12 ">
      <SurveyProgress />
      <div className="text-2xl text-neutral-900 font-serif">
        {currentQuestion.fieldType === "notes" && (
          <HTMLTagRenderer
            string={currentQuestion.fieldLabel}
            className="text-2xl font-medium text-neutral-900 font-serif"
          />
        )}
        {currentQuestion.textValidationTypeOrShowSliderNumber === "time" && (
          <HTMLTagRenderer
            string={currentQuestion.fieldLabel}
            className="text-2xl font-medium text-neutral-900 font-serif"
          />
        )}
        {currentQuestion.fieldLabel === "Halsomkreds" && (
          <div className="w-full flex flex-col items-center pb-8">
            <img
              src={neckMeasureIcon}
              alt="Neck Measure Image"
              className="w-36"
            />
          </div>
        )}
        {currentQuestion.fieldType === "slider" && (
          <div className="flex flex-col gap-2.5">
            <HTMLTagRenderer
              string={currentQuestion.fieldLabel}
              className="text-2xl font-medium text-neutral-900 font-serif"
            />
          </div>
        )}

        {currentQuestion.fieldType === "radio" ? (
          <div className="flex flex-col gap-2.5">
            <HTMLTagRenderer
              string={currentQuestion.sectionHeader}
              className="text-xl font-base text-neutral-900 font-serif"
            />
            <HTMLTagRenderer
              string={currentQuestion.fieldLabel}
              className="text-2xl font-medium text-neutral-900 font-serif"
            />
          </div>
        ) : (
          <div className="text-2xl text-neutral-900 font-serif">
            {currentQuestion.sectionHeader}
          </div>
        )}
      </div>
    </aside>
  )
}

export default SurveyQuestion
