import { Question } from "../../types/questionTypes"
import { useMultiPage } from "../../util/useMultiPage"
import OnboardingOverview from "../OnboardingOverview"
import SurveyComponent from "../SurveyComponent"
import "./index.scss"
import { SurveyContextProvider } from "./SurveyContext"
import { Provider } from "@rollbar/react"
import { getRollbarConfig } from "../../util/rollbarConfig"

export interface Survey {
  name: string
  metadata: Array<Question>
}

interface MainComponentProps {
  surveyId: string
  surveys: Array<Survey>
}

export default function Main({
  surveyId,
  surveys
}: MainComponentProps): JSX.Element {
  const allPages: Array<JSX.Element> = [
    <OnboardingOverview onStart={() => nextPage()} /> // Landing page
  ]

  surveys.forEach((survey, index) => {
    allPages.push(
      <SurveyComponent
        key={index}
        name={survey.name}
        onSurveySubmit={() => nextPage()}
        surveyMetadata={survey.metadata}
      />
    )

    allPages.push(<OnboardingOverview onStart={() => nextPage()} />)
  })

  const { currentPage, nextPage } = useMultiPage(allPages)

  return (
    <Provider config={getRollbarConfig()}>
      <SurveyContextProvider
        surveyIterationId={surveyId}
        surveys={surveys.map((survey) => survey.name)}
      >
        <div className="main">{currentPage}</div>
      </SurveyContextProvider>
    </Provider>
  )
}
