import { useContext } from "react"
import { SurveyCompletionEstimates } from "~/const/globalStrings"
import { SurveyContext } from "../Main/SurveyContext"
import Icon from "../Icon"

export const SurveyProgressCards = (): JSX.Element => {
  const context = useContext(SurveyContext)
  if (!context) return <></>
  const surveyIdsArray = Object.keys(context.status)

  return (
    <ul className=" flex flex-col gap-1.5">
      {surveyIdsArray.map((surveyId, index) => {
        const surveyEstimate = SurveyCompletionEstimates[surveyId]

        const isFinished = context.status[surveyId]
        const isCurrent = context.currentSurvey === surveyId
        return (
          <li
            key={index}
            className={`flex flex-col p-4 rounded-3xl gap-[2px] ${isCurrent ? "bg-neutral-50 border-[3px] border-primary-500" : "bg-neutral-100"} ${isFinished && "bg-neutral-50"} relative overflow-hidden`}
          >
            {isFinished && (
              <div className="bg-success-700 w-8 h-8 flex items-center justify-center absolute top-0 left-0 rounded-br-[10px]">
                <Icon name="check" className="w-5 h-5 text-success-50" />
              </div>
            )}
            <div className="flex text-neutral-700 justify-between items-center text-sm ">
              <span className={`${isFinished ? "pl-6" : "pl-0"} `}>
                Spørgeskema {index + 1}
              </span>

              <div className="flex gap-1 items-center">
                <Icon name="stopWatchSimple" className="w-4 h-4" />
                <span>ca.</span>
                <span>{surveyEstimate.estimatedTimeToComplete}</span>
                <span>min.</span>
              </div>
            </div>
            <p
              className={`${isCurrent || isFinished ? "text-neutral-900" : "text-neutral-600"} text-xl`}
            >
              {surveyEstimate.name}
            </p>
          </li>
        )
      })}
    </ul>
  )
}
