interface RadioOptions {
  label: string
  value: string
}

export function redcapChoicesToRadioOptions(
  choices: string
): Array<RadioOptions> {
  const options = choices.split("|")

  return options.map((str: string) => {
    const valueLabel = str.split(",")
    const value = valueLabel[0].replace(/\s/g, "")
    const label = valueLabel[1].replace(/^\s+|\s+$/g, "")
    return { label: label, value: value }
  })
}
