import { Data } from "~/types/dataTypes"
import { RadioQuestion } from "~/types/questionTypes"
import { redcapChoicesToRadioOptions } from "~/util/redcapChoicesToRadioOptions"
import RadioButton from "~/components/RadioButton"

type RadioButtonGroupProps = {
  data: Data
  question: RadioQuestion
  updateFields: (newData: Data) => void
  className?: string
}

export const RadioButtonGroup = (props: RadioButtonGroupProps): JSX.Element => {
  const {
    question: { fieldName, selectChoicesOrCalculations },
    data,
    updateFields,
    className
  } = props
  data[fieldName] = data[fieldName] || ""

  const optionsWithValues = redcapChoicesToRadioOptions(
    selectChoicesOrCalculations
  )

  return (
    <div className={`${className} flex flex-col gap-1.5`}>
      <p className="text-sm">Vælg én af følgende</p>
      {optionsWithValues.map(({ label, value }) => (
        <div key={fieldName + value}>
          <RadioButton
            required
            checked={data[fieldName] === value}
            key={fieldName + value}
            name={fieldName}
            label={label}
            value={value}
            onChange={(event) => {
              updateFields({ [fieldName]: event.target.value })
            }}
          />
        </div>
      ))}
    </div>
  )
}
