import { Question } from "../types/questionTypes"

export class UnsupportedFieldTypeError extends Error {
  formName: string

  constructor(question: Question) {
    const message = `unsupported field type \`${question.fieldType}\` for instrument \`${question.formName}\``
    super(message)
    this.name = "UnsupportedFieldTypeError"
    this.formName = question.formName
    this.stack = new Error().stack
  }
}
