import TimePicker from "react-time-picker"
import Input from "~/components/Input"
import { Data } from "~/types/dataTypes"
import { TextQuestion } from "~/types/questionTypes"
import parseFieldAnnotations from "~/util/parseFieldAnnotations"

type TextInputProps = {
  data: Data
  question: TextQuestion
  updateFields: (newData: Data) => void
  className?: string
  inputLabel?: string
}

export const TextInput = (props: TextInputProps): JSX.Element => {
  const {
    fieldAnnotation,
    fieldName,
    textValidationMin,
    textValidationMax,
    textValidationTypeOrShowSliderNumber
  } = props.question
  const { data, updateFields, inputLabel } = props
  data[fieldName] = data[fieldName] || ""

  const minMaxProps = {
    ...(textValidationMin && { min: textValidationMin }),
    ...(textValidationMax && { max: textValidationMax })
  }

  const parsedFieldAnnotation = parseFieldAnnotations(fieldAnnotation)
  let placeholder = parsedFieldAnnotation.placeholder as string
  if (placeholder) {
    placeholder = placeholder.replace(/['"]/g, "")
  }

  if (textValidationTypeOrShowSliderNumber === "time") {
    return (
      <div className="mb-10 flex flex-col gap-1.5">
        <p>Vælg tidspunkt</p>
        <div className="w-full">
          <TimePicker
            required
            className="w-full h-[50px]"
            name={fieldName}
            value={data[fieldName]}
            onChange={(newValue) =>
              newValue && updateFields({ [fieldName]: String(newValue) })
            }
            hourPlaceholder="tt"
            minutePlaceholder="mm"
            format="HH:mm"
            disableClock
            clearIcon={null}
          />
        </div>
      </div>
    )
  } else if (textValidationTypeOrShowSliderNumber === "number") {
    return (
      <Input
        autoFocus
        required
        placeholder={placeholder}
        type="number"
        step="1"
        name={fieldName}
        label={inputLabel}
        value={data[fieldName] || ""}
        onChange={(event) => {
          updateFields({ [fieldName]: event.target.value })
        }}
        {...minMaxProps}
        className={"mb-6"}
      />
    )
  } else if (textValidationTypeOrShowSliderNumber === "integer") {
    return (
      <Input
        autoFocus
        required
        placeholder={placeholder}
        type="number"
        step="1"
        name={fieldName}
        label={inputLabel}
        value={data[fieldName] || ""}
        onChange={(event) => {
          updateFields({ [fieldName]: event.target.value })
        }}
        {...minMaxProps}
        className={"mb-6"}
      />
    )
  } else if (textValidationTypeOrShowSliderNumber === "email") {
    return (
      <Input
        autoFocus
        required
        placeholder={placeholder}
        type="email"
        name={fieldName}
        label={inputLabel}
        value={data[fieldName]}
        onChange={(event) => {
          updateFields({ [fieldName]: event.target.value })
        }}
        className={"mb-6"}
      />
    )
  } else {
    return (
      <Input
        autoFocus
        required
        placeholder={placeholder}
        type="text"
        name={fieldName}
        label={inputLabel}
        value={data[fieldName] || ""}
        onChange={(event) => {
          updateFields({ [fieldName]: event.target.value })
        }}
        className={"mb-6"}
      />
    )
  }
}
