import Slider from "@mui/material/Slider"
import { useEffect, useState } from "react"
import { Data } from "~/types/dataTypes"
import { SliderQuestion } from "~/types/questionTypes"

type SliderInputProps = {
  data: Data
  question: SliderQuestion
  updateFields: (newData: Data) => void
}

export const SliderInput = (props: SliderInputProps): JSX.Element => {
  const {
    data,
    question,
    question: { fieldName },
    updateFields
  } = props
  const maxVal = parseInt(question.textValidationMax)
  const [currentValue, setCurrentValue] = useState<number>(
    Math.ceil(maxVal / 2)
  )

  const marks = question.selectChoicesOrCalculations
    .split("|")
    .reduce(function (result: { value: number; label: string }[], str: string) {
      const trimStr = str.trim()

      /* istanbul ignore else */
      if (!isNaN(parseInt(trimStr.charAt(0)))) {
        const splitStr = trimStr.split(" ")
        const labelSlice = splitStr.slice(1).join(" ").trim()
        result.push({ value: parseInt(splitStr[0]), label: labelSlice })
      } else if (!isNaN(parseInt(trimStr.charAt(trimStr.length - 1)))) {
        const splitStr = trimStr.split(" ")
        const labelSlice = splitStr
          .slice(0, splitStr.length - 1)
          .join(" ")
          .trim()
        result.push({
          value: parseInt(splitStr?.[splitStr.length - 1]),
          label: labelSlice
        })
      }
      return result
    }, [])

  interface Mark {
    value: number
    label: number | string
    "data-chosen"?: boolean
  }

  const handleMarkUpdate = (marks: Mark[], currentValue: number) => {
    return marks.map((mark: Mark) => ({
      ...mark,
      "data-chosen": mark.value === currentValue
    }))
  }

  useEffect(() => {
    updateFields({ [fieldName]: String(currentValue) })
    const marks = document.querySelectorAll(".MuiSlider-markLabel")
    marks.forEach((mark) => {
      const dataIndex = mark.getAttribute("data-index")
      if (Number(dataIndex) + 1 === currentValue) {
        mark.setAttribute("data-chosen", "true")
      } else {
        mark.removeAttribute("data-chosen")
      }
    })
  }, [currentValue])
  return (
    <div className="flex flex-col gap-14">
      <p>Træk slideren til det passende nummer</p>
      <div>
        <Slider
          className={`${data[fieldName] ? "slider-valid" : ""}`}
          aria-label="Slider"
          value={parseInt(data[fieldName]) || Math.ceil(maxVal / 2)}
          step={1}
          min={1}
          marks={handleMarkUpdate(
            [
              ...Array(maxVal)
                .fill(0)
                .map((_, i) => ({ value: i + 1, label: i + 1 }))
            ],
            currentValue
          )}
          max={maxVal}
          onChange={(_, newValue) => {
            setCurrentValue(newValue as number)
            updateFields({ [fieldName]: String(newValue) })
          }}
          sx={{
            color: "#f7c196",
            "& .MuiSlider-thumb": {
              color: "#BA7E56"
            },
            "& .MuiSlider-track": {
              height: "10px"
            },
            "& .MuiSlider-rail": {
              color: "#DECFC6"
            },
            '& .MuiSlider-markLabel[data-chosen="true"]': {
              color: "#493C33",
              fontWeight: "bold"
            }
          }}
          data-index={currentValue}
        />
        <div className="flex flex-row justify-between">
          {marks.map((mark) => (
            <p key={`slider-label-${mark.value}`}>{mark.label}</p>
          ))}
        </div>
        <input
          className="invisible"
          type="number"
          name="slider-valid-input"
          value={data[fieldName] ?? ""}
          readOnly
          required
        />
      </div>
    </div>
  )
}
