export function getRollbarConfig() {
  return {
    accessToken: process.env.REACT_APP_ROLLBAR_CLIENT_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      client: {
        javascript: {
          sourceMapEnabled: true
        }
      }
    }
  }
}
