export type IconType = keyof typeof iconPaths

const iconPaths = {
  arrowLeft: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7801 19.03C10.6394 19.1705 10.4488 19.2494 10.2501 19.2494C10.0513 19.2494 9.8607 19.1705 9.72007 19.03L3.47007 12.78C3.32962 12.6394 3.25073 12.4488 3.25073 12.25C3.25073 12.0513 3.32962 11.8607 3.47007 11.72L9.72007 5.47003C9.78873 5.39634 9.87153 5.33724 9.96353 5.29625C10.0555 5.25526 10.1548 5.23322 10.2556 5.23144C10.3563 5.22966 10.4563 5.24819 10.5497 5.28591C10.6431 5.32363 10.7279 5.37977 10.7991 5.45099C10.8703 5.52221 10.9265 5.60705 10.9642 5.70043C11.0019 5.79382 11.0204 5.89385 11.0187 5.99455C11.0169 6.09526 10.9948 6.19457 10.9539 6.28657C10.9129 6.37857 10.8538 6.46137 10.7801 6.53003L5.81007 11.5H20.2501C20.449 11.5 20.6398 11.579 20.7804 11.7197C20.9211 11.8604 21.0001 12.0511 21.0001 12.25C21.0001 12.4489 20.9211 12.6397 20.7804 12.7804C20.6398 12.921 20.449 13 20.2501 13H5.81007L10.7801 17.97C10.9205 18.1107 10.9994 18.3013 10.9994 18.5C10.9994 18.6988 10.9205 18.8894 10.7801 19.03Z"
        fill="currentColor"
      />
    </>
  ),
  arrowRight: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4754 19.03C13.616 19.1705 13.8067 19.2494 14.0054 19.2494C14.2042 19.2494 14.3948 19.1705 14.5354 19.03L20.7854 12.78C20.9259 12.6394 21.0048 12.4488 21.0048 12.25C21.0048 12.0513 20.9259 11.8607 20.7854 11.72L14.5354 5.47003C14.4667 5.39634 14.3839 5.33724 14.2919 5.29625C14.2 5.25526 14.1006 5.23322 13.9999 5.23144C13.8992 5.22966 13.7992 5.24819 13.7058 5.28591C13.6124 5.32363 13.5276 5.37977 13.4564 5.45099C13.3852 5.52221 13.329 5.60705 13.2913 5.70043C13.2536 5.79382 13.235 5.89385 13.2368 5.99455C13.2386 6.09526 13.2606 6.19457 13.3016 6.28657C13.3426 6.37857 13.4017 6.46137 13.4754 6.53003L18.4454 11.5L3.75 11.5C3.55109 11.5 3.36032 11.5791 3.21967 11.7197C3.07902 11.8604 3 12.0511 3 12.25C3 12.4489 3.07902 12.6397 3.21967 12.7804C3.36032 12.921 3.55109 13 3.75 13L18.4454 13L13.4754 17.97C13.335 18.1107 13.2561 18.3013 13.2561 18.5C13.2561 18.6988 13.335 18.8894 13.4754 19.03Z"
        fill="currentColor"
      />
    </>
  ),
  infoCircle: (
    <>
      <path
        d="M13 7.5C13 7.76522 12.8946 8.01957 12.7071 8.20711C12.5196 8.39464 12.2652 8.5 12 8.5C11.7348 8.5 11.4804 8.39464 11.2929 8.20711C11.1054 8.01957 11 7.76522 11 7.5C11 7.23478 11.1054 6.98043 11.2929 6.79289C11.4804 6.60536 11.7348 6.5 12 6.5C12.2652 6.5 12.5196 6.60536 12.7071 6.79289C12.8946 6.98043 13 7.23478 13 7.5ZM10 11.25C10 11.0511 10.079 10.8603 10.2197 10.7197C10.3603 10.579 10.5511 10.5 10.75 10.5H12.25C12.4489 10.5 12.6397 10.579 12.7803 10.7197C12.921 10.8603 13 11.0511 13 11.25V15.5H13.75C13.9489 15.5 14.1397 15.579 14.2803 15.7197C14.421 15.8603 14.5 16.0511 14.5 16.25C14.5 16.4489 14.421 16.6397 14.2803 16.7803C14.1397 16.921 13.9489 17 13.75 17H10.75C10.5511 17 10.3603 16.921 10.2197 16.7803C10.079 16.6397 10 16.4489 10 16.25C10 16.0511 10.079 15.8603 10.2197 15.7197C10.3603 15.579 10.5511 15.5 10.75 15.5H11.5V12H10.75C10.5511 12 10.3603 11.921 10.2197 11.7803C10.079 11.6397 10 11.4489 10 11.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C5.925 1 1 5.925 1 12C1 18.075 5.925 23 12 23C18.075 23 23 18.075 23 12C23 5.925 18.075 1 12 1ZM2.5 12C2.5 9.48044 3.50089 7.06408 5.28249 5.28249C7.06408 3.50089 9.48044 2.5 12 2.5C14.5196 2.5 16.9359 3.50089 18.7175 5.28249C20.4991 7.06408 21.5 9.48044 21.5 12C21.5 14.5196 20.4991 16.9359 18.7175 18.7175C16.9359 20.4991 14.5196 21.5 12 21.5C9.48044 21.5 7.06408 20.4991 5.28249 18.7175C3.50089 16.9359 2.5 14.5196 2.5 12Z"
        fill="currentColor"
      />
    </>
  ),
  stopWatchSimple: (
    <>
      <path
        d="M5.83333 1.33337C5.55719 1.33337 5.33333 1.55723 5.33333 1.83337C5.33333 2.10952 5.55719 2.33337 5.83333 2.33337H10.1667C10.4428 2.33337 10.6667 2.10952 10.6667 1.83337C10.6667 1.55723 10.4428 1.33337 10.1667 1.33337H5.83333Z"
        fill="currentColor"
      />
      <path
        d="M8.5 5.83337V9.50004C8.5 9.77618 8.27614 10 8 10C7.72386 10 7.5 9.77618 7.5 9.50004V5.83337C7.5 5.55723 7.72386 5.33337 8 5.33337C8.27614 5.33337 8.5 5.55723 8.5 5.83337Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3.33337C4.68629 3.33337 2 6.01967 2 9.33337C2 12.6471 4.68629 15.3334 8 15.3334C11.3137 15.3334 14 12.6471 14 9.33337C14 8.12354 13.6419 6.99733 13.026 6.05496L13.8418 5.23911C14.1022 4.97876 14.1022 4.55665 13.8418 4.2963L13.0775 3.532C12.8172 3.27165 12.3951 3.27165 12.1347 3.532L11.3271 4.33959C10.375 3.70394 9.23075 3.33337 8 3.33337ZM3 9.33337C3 6.57195 5.23858 4.33337 8 4.33337C10.7614 4.33337 13 6.57195 13 9.33337C13 12.0948 10.7614 14.3334 8 14.3334C5.23858 14.3334 3 12.0948 3 9.33337Z"
        fill="currentColor"
      />
    </>
  ),
  check: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3166 4.35008C17.4337 4.46727 17.4994 4.62612 17.4994 4.79175C17.4994 4.95737 17.4337 5.11623 17.3166 5.23341L8.56663 14.8167C8.50777 14.8756 8.43775 14.922 8.36069 14.9534C8.28363 14.9848 8.20107 15.0005 8.11787 14.9996C8.03466 14.9987 7.95247 14.9811 7.87613 14.948C7.79979 14.9149 7.73083 14.8669 7.6733 14.8067L2.6733 10.0151C2.614 9.9563 2.56707 9.88623 2.5353 9.80901C2.50353 9.73179 2.48755 9.64899 2.48831 9.5655C2.48907 9.482 2.50655 9.3995 2.53973 9.32288C2.5729 9.24625 2.62109 9.17705 2.68146 9.11935C2.74182 9.06166 2.81313 9.01665 2.89118 8.98698C2.96923 8.95731 3.05244 8.94357 3.13588 8.94659C3.21933 8.94961 3.30132 8.96932 3.37702 9.00456C3.45272 9.0398 3.5206 9.08984 3.57663 9.15175L8.13497 13.4809L16.4333 4.35008C16.5505 4.23304 16.7093 4.1673 16.875 4.1673C17.0406 4.1673 17.1994 4.23304 17.3166 4.35008Z"
        fill="currentColor"
      />
    </>
  ),
  package: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.876 0.640004C12.6099 0.486409 12.3082 0.405548 12.001 0.405548C11.6938 0.405548 11.392 0.486409 11.126 0.640004L2.87598 5.402C2.61002 5.55555 2.38916 5.77638 2.23557 6.04231C2.08198 6.30824 2.00107 6.60991 2.00098 6.917V16.442C2.00098 17.067 2.33498 17.645 2.87598 17.957L11.126 22.72C11.392 22.8736 11.6938 22.9545 12.001 22.9545C12.3082 22.9545 12.6099 22.8736 12.876 22.72L21.126 17.958C21.3921 17.8044 21.613 17.5834 21.7666 17.3173C21.9202 17.0511 22.0011 16.7493 22.001 16.442V6.917C22.0009 6.60991 21.92 6.30824 21.7664 6.04231C21.6128 5.77638 21.3919 5.55555 21.126 5.402L12.876 0.639004V0.640004ZM11.876 1.938C11.914 1.91606 11.9571 1.90451 12.001 1.90451C12.0449 1.90451 12.088 1.91606 12.126 1.938L19.751 6.34L12.001 10.814L4.25098 6.34L11.876 1.938ZM3.50098 7.64V16.443C3.50098 16.533 3.54898 16.615 3.62598 16.659L11.251 21.061V12.114L3.50098 7.64ZM12.751 21.061L20.376 16.659C20.4139 16.6371 20.4454 16.6056 20.4674 16.5677C20.4893 16.5298 20.5009 16.4868 20.501 16.443V7.639L12.751 12.113V21.06V21.061Z"
        fill="currentColor"
      />
    </>
  ),
  video: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666626 6.25C0.666626 5.2835 1.45013 4.5 2.41663 4.5H15.4166C16.3831 4.5 17.1666 5.2835 17.1666 6.25V8.25L22.8284 5.23039C23.661 4.78632 24.6666 5.38968 24.6666 6.33333V17.1667C24.6666 18.1103 23.661 18.7137 22.8284 18.2696L17.1666 15.25V17.25C17.1666 18.2165 16.3831 19 15.4166 19H2.41663C1.45013 19 0.666626 18.2165 0.666626 17.25V6.25ZM17.1666 13.55L23.1666 16.75V6.75L17.1666 9.95V13.55ZM2.41663 6C2.27855 6 2.16663 6.11193 2.16663 6.25V17.25C2.16663 17.3881 2.27855 17.5 2.41663 17.5H15.4166C15.5547 17.5 15.6666 17.3881 15.6666 17.25V6.25C15.6666 6.11193 15.5547 6 15.4166 6H2.41663Z"
        fill="currentColor"
      />
    </>
  ),
  iphoneShake: (
    <>
      <path
        d="M7.94346 18.6565C7.99977 18.2462 8.38881 17.9606 8.81242 18.0187L13.9578 18.7247C14.3814 18.7828 14.6791 19.1626 14.6228 19.573C14.5665 19.9833 14.1775 20.2689 13.7539 20.2108L8.60852 19.5048C8.18492 19.4467 7.88716 19.0669 7.94346 18.6565Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.95969 2.73912C6.16614 1.23444 7.59263 0.187408 9.14586 0.400517L18.3819 1.66774C19.9351 1.88085 21.0269 3.2734 20.8205 4.77809L18.5776 21.1249C18.3712 22.6296 16.9447 23.6767 15.3914 23.4635L6.15538 22.1963C4.60215 21.9832 3.51038 20.5907 3.71683 19.086L5.95969 2.73912ZM8.94196 1.88659C8.23595 1.78973 7.58754 2.26565 7.4937 2.9496L5.25084 19.2964C5.157 19.9804 5.65326 20.6134 6.35927 20.7102L15.5953 21.9775C16.3013 22.0743 16.9497 21.5984 17.0436 20.9145L19.2864 4.56761C19.3803 3.88367 18.884 3.25069 18.178 3.15382L15.877 2.83811L15.809 3.33347C15.734 3.88063 15.2152 4.26137 14.6504 4.18387L12.0618 3.8287C11.497 3.7512 11.0999 3.24482 11.175 2.69766L11.243 2.2023L8.94196 1.88659Z"
        fill="currentColor"
      />
      <path
        d="M21.5905 15.1019C22.0008 15.1582 22.2879 15.5366 22.2315 15.9469L21.348 22.3866C21.2917 22.797 20.9134 23.084 20.503 23.0277C20.0926 22.9714 19.8056 22.5931 19.8619 22.1827L20.7455 15.743C20.8018 15.3327 21.1801 15.0456 21.5905 15.1019Z"
        fill="currentColor"
      />
      <path
        d="M4.16394 1.10194C4.57431 1.15824 4.86134 1.53656 4.80503 1.94693L3.92148 8.3866C3.86518 8.79696 3.48686 9.08399 3.0765 9.02769C2.66613 8.97138 2.3791 8.59307 2.43541 8.1827L3.31896 1.74303C3.37526 1.33266 3.75358 1.04564 4.16394 1.10194Z"
        fill="currentColor"
      />
    </>
  ),
  email: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.0835 3C1.61937 3 1.17425 3.18437 0.846059 3.51256C0.517871 3.84075 0.333496 4.28587 0.333496 4.75L0.333496 18.75C0.333496 19.716 1.1175 20.5 2.0835 20.5H22.5835C23.0476 20.5 23.4927 20.3156 23.8209 19.9874C24.1491 19.6592 24.3335 19.2141 24.3335 18.75V4.75C24.3335 4.28587 24.1491 3.84075 23.8209 3.51256C23.4927 3.18437 23.0476 3 22.5835 3H2.0835ZM1.8335 4.75C1.8335 4.6837 1.85984 4.62011 1.90672 4.57322C1.9536 4.52634 2.01719 4.5 2.0835 4.5H22.5835C22.6498 4.5 22.7134 4.52634 22.7603 4.57322C22.8072 4.62011 22.8335 4.6837 22.8335 4.75V5.602L12.4735 12.602C12.4322 12.6299 12.3834 12.6449 12.3335 12.6449C12.2836 12.6449 12.2348 12.6299 12.1935 12.602L1.8335 5.602V4.75ZM1.8335 7.412V18.75C1.8335 18.888 1.9455 19 2.0835 19H22.5835C22.6498 19 22.7134 18.9737 22.7603 18.9268C22.8072 18.8799 22.8335 18.8163 22.8335 18.75V7.412L13.3135 13.845C12.7215 14.245 11.9455 14.245 11.3535 13.845L1.8335 7.412Z"
        fill="currentColor"
      />
    </>
  ),
  link: (
    <>
      <path
        d="M9.85333 2.43534C10.097 2.19165 10.3863 1.99834 10.7047 1.86646C11.0231 1.73457 11.3644 1.66669 11.709 1.66669C12.0536 1.66669 12.3949 1.73457 12.7133 1.86646C13.0317 1.99834 13.321 2.19165 13.5647 2.43534C13.8084 2.67903 14.0017 2.96833 14.1335 3.28672C14.2654 3.60512 14.3333 3.94637 14.3333 4.291C14.3333 4.63563 14.2654 4.97689 14.1335 5.29528C14.0017 5.61368 13.8084 5.90298 13.5647 6.14667L11.1467 8.56467C10.8843 8.82714 10.5692 9.03096 10.2222 9.16267C9.87518 9.29438 9.5042 9.35099 9.13373 9.32875C8.76325 9.3065 8.40171 9.20592 8.07297 9.03365C7.74423 8.86137 7.45578 8.62132 7.22666 8.32934C7.14462 8.22502 7.0245 8.15756 6.89273 8.14181C6.76095 8.12606 6.62831 8.1633 6.524 8.24534C6.41968 8.32738 6.35222 8.4475 6.33647 8.57927C6.32071 8.71105 6.35795 8.84368 6.44 8.948C6.75651 9.35109 7.15491 9.68247 7.60892 9.92025C8.06293 10.158 8.5622 10.2968 9.07379 10.3274C9.58539 10.3581 10.0977 10.2798 10.5768 10.0979C11.0559 9.91595 11.491 9.63446 11.8533 9.272L14.272 6.85334C14.6085 6.5168 14.8755 6.11728 15.0576 5.67757C15.2397 5.23787 15.3335 4.7666 15.3335 4.29067C15.3335 3.81474 15.2397 3.34347 15.0576 2.90376C14.8755 2.46406 14.6085 2.06454 14.272 1.728C13.9355 1.39147 13.5359 1.12452 13.0962 0.942384C12.6565 0.760253 12.1853 0.666512 11.7093 0.666512C11.2334 0.666512 10.7621 0.760253 10.3224 0.942384C9.88272 1.12452 9.4832 1.39147 9.14666 1.728L7.14666 3.728C7.05554 3.82226 7.00508 3.94854 7.00616 4.07964C7.00723 4.21074 7.05976 4.33617 7.15242 4.42892C7.24508 4.52167 7.37046 4.57431 7.50156 4.57551C7.63266 4.57671 7.75898 4.52637 7.85333 4.43534L9.85333 2.43534Z"
        fill="currentColor"
      />
      <path
        d="M4.85333 7.43534C5.11573 7.17286 5.43083 6.96904 5.77782 6.83733C6.12481 6.70562 6.49579 6.64902 6.86626 6.67126C7.23674 6.6935 7.59828 6.79408 7.92702 6.96636C8.25576 7.13863 8.54421 7.37869 8.77333 7.67067C8.81395 7.72232 8.86435 7.76547 8.92165 7.79764C8.97894 7.82982 9.04202 7.8504 9.10726 7.8582C9.17251 7.866 9.23866 7.86087 9.30193 7.8431C9.36519 7.82534 9.42434 7.79529 9.47599 7.75467C9.52765 7.71405 9.57079 7.66365 9.60297 7.60635C9.63515 7.54905 9.65572 7.48598 9.66352 7.42073C9.67132 7.35548 9.66619 7.28934 9.64843 7.22607C9.63067 7.1628 9.60062 7.10366 9.55999 7.052C9.24348 6.64891 8.84507 6.31754 8.39107 6.07975C7.93706 5.84197 7.43778 5.70319 6.92619 5.67257C6.4146 5.64195 5.90234 5.72019 5.42321 5.90212C4.94408 6.08405 4.50899 6.36554 4.14666 6.728L1.728 9.14667C1.39146 9.4832 1.12451 9.88273 0.942377 10.3224C0.760246 10.7621 0.666504 11.2334 0.666504 11.7093C0.666504 12.1853 0.760246 12.6565 0.942377 13.0962C1.12451 13.5359 1.39146 13.9355 1.728 14.272C2.06453 14.6085 2.46405 14.8755 2.90376 15.0576C3.34346 15.2398 3.81473 15.3335 4.29066 15.3335C4.76659 15.3335 5.23786 15.2398 5.67757 15.0576C6.11727 14.8755 6.51679 14.6085 6.85333 14.272L8.85333 12.272C8.90245 12.2262 8.94185 12.171 8.96918 12.1097C8.99651 12.0484 9.01121 11.9822 9.01239 11.915C9.01357 11.8479 9.00122 11.7812 8.97608 11.7189C8.95093 11.6567 8.9135 11.6001 8.86602 11.5526C8.81854 11.5052 8.76199 11.4677 8.69973 11.4426C8.63747 11.4174 8.57078 11.4051 8.50365 11.4063C8.43651 11.4075 8.3703 11.4222 8.30897 11.4495C8.24764 11.4768 8.19244 11.5162 8.14666 11.5653L6.14666 13.5653C5.90293 13.809 5.61358 14.0023 5.29515 14.1342C4.97671 14.2661 4.63542 14.3339 4.29076 14.3339C3.59469 14.3338 2.92715 14.0572 2.435 13.565C2.19131 13.3213 1.99801 13.0319 1.86614 12.7135C1.73427 12.3951 1.66642 12.0538 1.66645 11.7091C1.66651 11.013 1.94309 10.3455 2.43533 9.85334L4.85333 7.43534Z"
        fill="currentColor"
      />
    </>
  ),
  call: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.45839 1.91706C4.68124 1.13991 3.42141 1.13991 2.64426 1.91706L2.15848 2.40284C1.48255 3.07877 1.20967 4.04542 1.38744 4.98759C1.77669 7.04653 2.96342 9.26588 4.84888 11.1513C6.73436 13.0368 8.9539 14.2236 11.0128 14.6128C11.955 14.7906 12.9215 14.5177 13.5974 13.8417L14.0824 13.3567C14.8596 12.5795 14.8596 11.3197 14.0824 10.5426L13.2085 9.66862C12.5768 9.03695 11.5522 9.03648 10.9208 9.66895L10.2482 10.3423C10.2367 10.3538 10.2228 10.3606 10.2104 10.3632C10.199 10.3655 10.1918 10.3638 10.1877 10.362C9.25536 9.95022 8.33043 9.32795 7.50134 8.49888C6.67238 7.66995 6.05086 6.745 5.63976 5.81267C5.63796 5.80858 5.63612 5.80144 5.63853 5.7898C5.64108 5.77746 5.64806 5.76348 5.65956 5.75192L6.26976 5.14246C6.94676 4.46546 6.98266 3.44134 6.33309 2.79176L5.45839 1.91706ZM4.75128 2.62417C4.36466 2.23754 3.73799 2.23754 3.35136 2.62417L2.86558 3.10995C2.43738 3.53815 2.24984 4.16454 2.37003 4.80183C2.71693 6.63652 3.79222 8.68048 5.55599 10.4443C7.31982 12.2081 9.3637 13.2833 11.1984 13.6302C11.8357 13.7504 12.4621 13.5629 12.8903 13.1347L13.3754 12.6496C13.762 12.263 13.762 11.6363 13.3754 11.2497L12.5014 10.3757C12.2599 10.1342 11.8689 10.1347 11.6285 10.3755L10.9556 11.0491C10.659 11.3457 10.1959 11.4587 9.78383 11.2769C8.7359 10.8139 7.7086 10.1203 6.79423 9.20602C5.87979 8.29155 5.18694 7.26426 4.72482 6.21626C4.54309 5.80428 4.65606 5.34121 4.95266 5.0446L5.56265 4.43536C5.88552 4.11249 5.84961 3.7225 5.62598 3.49887L4.75128 2.62417Z"
        fill="currentColor"
      />
    </>
  ),
  dotsHorizontal: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.99992 8C3.99992 8.35362 3.85944 8.69276 3.60939 8.94281C3.35935 9.19286 3.02021 9.33333 2.66659 9.33333C2.31296 9.33333 1.97382 9.19286 1.72378 8.94281C1.47373 8.69276 1.33325 8.35362 1.33325 8C1.33325 7.64638 1.47373 7.30724 1.72378 7.05719C1.97382 6.80714 2.31296 6.66667 2.66659 6.66667C3.02021 6.66667 3.35935 6.80714 3.60939 7.05719C3.85944 7.30724 3.99992 7.64638 3.99992 8ZM9.33325 8C9.33325 8.35362 9.19278 8.69276 8.94273 8.94281C8.69268 9.19286 8.35354 9.33333 7.99992 9.33333C7.6463 9.33333 7.30716 9.19286 7.05711 8.94281C6.80706 8.69276 6.66659 8.35362 6.66659 8C6.66659 7.64638 6.80706 7.30724 7.05711 7.05719C7.30716 6.80714 7.6463 6.66667 7.99992 6.66667C8.35354 6.66667 8.69268 6.80714 8.94273 7.05719C9.19278 7.30724 9.33325 7.64638 9.33325 8ZM13.3333 9.33333C13.6869 9.33333 14.026 9.19286 14.2761 8.94281C14.5261 8.69276 14.6666 8.35362 14.6666 8C14.6666 7.64638 14.5261 7.30724 14.2761 7.05719C14.026 6.80714 13.6869 6.66667 13.3333 6.66667C12.9796 6.66667 12.6405 6.80714 12.3904 7.05719C12.1404 7.30724 11.9999 7.64638 11.9999 8C11.9999 8.35362 12.1404 8.69276 12.3904 8.94281C12.6405 9.19286 12.9796 9.33333 13.3333 9.33333Z"
        fill="currentColor"
      />
    </>
  )
}

function IconPath({ name }: { name: IconType }) {
  const path = iconPaths[name]
  return <>{path}</>
}

export default IconPath
