import React, { useContext } from "react"
import { SurveyContext } from "../Main/SurveyContext"

const SurveyProgress: React.FC = () => {
  const surveyContext = useContext(SurveyContext)

  if (!surveyContext) {
    return null
  }

  const { currentSurveyNumber, currentStepNumber, totalSteps } = surveyContext

  return (
    <div className="flex justify-between text-neutral-700 font-semibold">
      <span>Spørgeskema {currentSurveyNumber}</span>
      <span>
        {currentStepNumber}/{totalSteps - 1}
      </span>
    </div>
  )
}

export default SurveyProgress
